<template>
  <div class="content-detail-wrap flex">
    <Left v-if="!isfoldLeft" id="left-wrap" class="left" :order-information="orderInformation" :title-type="titleType" />
    <div :class="['middle card-padding', isfoldLeft ? 'middle-all' : 'middle-half']">
      <div id="scroll-wrap" ref="scrollWrap" class="scroll-wrap">
        <div class="table-wrap">
          <div class="arrow" :class="isfoldLeft ? 'arrow-left' : 'arrow1'" @click="foldLeft">
            <i :class="isfoldLeft ? 'el-icon-arrow-right' : 'el-icon-arrow-left'" />
            <span v-if="isfoldLeft">{{ titleType }}信息</span>
          </div>
          <div class="tabs-wrap">
            <div v-if="status===2&&countInfo.messageCount" class="task-tip"><i class="iconfont icon-information-line" />您有 {{ countInfo.messageCount||0 }} 条改派/取消信息待处理</div>
            <Mtabs :tabs="tabs" class="tabs" :init-tab-index="activeTabIndex" @changeTab="changeTab" @refresh="refreshData" />
          </div>
          <div class="tab-down-wrap">
            <!-- 未承接详情 -->
            <template v-if="status===1">
              <AcceptPushInfoSimple v-if="activeTabIndex === 0&&goodsBillType===2" :key="componentKey" :order-id="Number(orderId)" :order-information="orderInformation" @toSendDetail="toSendDetail" />
              <AcceptPushInfo v-if="activeTabIndex ===0&&goodsBillType===1" :key="componentKey" :order-id="Number(orderId)" :order-information="orderInformation" @toSendDetail="toSendDetail" />
            </template>
            <!-- 承接中详情 -->
            <template v-if="status===2">
              <template v-if="+isSend===0">
                <AcceptPushInfo
                  v-if="activeTabIndex === 0"
                  :key="componentKey"
                  :order-id="Number(orderId)"
                  :order-information="orderInformation"
                  :show-actions="![8,9].includes(+orderInformation.state)"
                  @toSendDetail="toSendDetail"
                />
              </template>
              <template v-if="+isSend===1">
                <AcceptPushInfoDetail v-if="activeTabIndex === 0" :key="componentKey" :order-id="Number(orderId)" :order-information="orderInformation" :show-actions="![8,9].includes(+orderInformation.state)" @actionSuccess="refreshCount" />
                <WaitConfirmOwner v-if="activeTabIndex === 1" :key="componentKey" :order-id="Number(orderId) " :order-information="orderInformation" :show-actions="![8,9].includes(+orderInformation.state)" @actionSuccess="refreshCount" />
                <WaitOwnerConfirm v-if="activeTabIndex === 2" :key="componentKey" :order-id="Number(orderId)" :order-information="orderInformation" :show-actions="![8,9].includes(+orderInformation.state)" @actionSuccess="refreshCount" />
                <OwnerConfirmed v-if="activeTabIndex === 3" :key="componentKey" :order-id="Number(orderId)" />
                <ReassignAndCancel v-if="activeTabIndex === 4" :key="componentKey" :order-id="Number(orderId)" :show-action="true" @actionSuccess="refreshCount" />
              </template>
            </template>
            <!-- 承接记录详情 -->
            <template v-if="status===3">
              <template v-if="+goodsBillType===1">
                <OwnerConfirmed v-if="activeTabIndex === 0" :key="componentKey" :order-id="Number(orderId)" :type="3" />
                <HasCancel v-if="activeTabIndex === 1" :key="componentKey" :order-id="Number(orderId)" />
              </template>
              <template v-if="+goodsBillType===2">
                <AcceptPushInfoSimple v-if="activeTabIndex === 0&&goodsBillType===2" :key="componentKey" :order-id="Number(orderId)" :show-detail="true" />
              </template>
            </template>
          </div>
        </div>
      </div>
      <transition name="fade">
        <div v-show="showBackTop" class="back-to-top" @click="backToTop">
          <i class="el-icon-top" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import supplyTask from '@/api/goodsOrder/supplyTask';
import Left from '@/components/OrderBusiness/left';
import Mtabs from '@/components/OrderBusiness/Mtabs';
import AcceptPushInfoSimple from '@/views/goodsOrder/components/detail/QX/acceptPushInfoSimple';
import AcceptPushInfo from '@/views/goodsOrder/components/detail/QX/acceptPushInfo';
import AcceptPushInfoDetail from '@/views/goodsOrder/components/detail/QX/acceptPushInfoDetail';
import WaitConfirmOwner from '@/views/goodsOrder/components/detail/QX/waitConfirmOwner';
import WaitOwnerConfirm from '@/views/goodsOrder/components/detail/QX/waitOwnerConfirm';
import OwnerConfirmed from '@/views/goodsOrder/components/detail/ownerConfirmed';
import ReassignAndCancel from '@/views/goodsOrder/components/detail/reassignAndCancel';
import HasCancel from '@/views/goodsOrder/components/detail/QX/hasCancel';

export default {
  // name: 'GoodsorderSupplytaskDetail',
  components: { Left, Mtabs, AcceptPushInfoSimple, AcceptPushInfo, AcceptPushInfoDetail, WaitConfirmOwner, WaitOwnerConfirm, OwnerConfirmed, ReassignAndCancel, HasCancel },
  data() {
    return {
      titleType: '货源',
      componentKey: 1,
      orderId: '',
      status: '', // 1未承接 2承接中 3货单跟踪 4未中标
      goodsBillType: '', // 1未承接下面的订单类型 2 竞价报车 1定价报车
      isSend: '', // 2承接中下面的推送状态 0 未推送 1已推送
      orderInformation: {}, // 货单信息
      activeTabIndex: 0,
      tabMap: {
        1: [
          { label: '承接推送信息', value: 0 },
        ],
        2: [
          { label: '承接推送信息', value: 0 },
        ],
        3: [
          { label: '承接推送信息', value: 0 },
        ],
      },
      tabs: [],
      isfoldLeft: false, // 左侧是否展开铺满
      showBackTop: false,
      countInfo: {
        messageCount: 0, // 通知消息统计
        pushCount: 0, // 承运推送统计
        readySubmitGoodsCount: 0, // 待提交货主统计
        readyGoodsConfirmCount: 0, // 待货主确认统计
        goodsPassConfirmCount: 0, // 货主已确认统计
        cancelCount: 0, // 取消改派统计
      },
    };
  },
  computed: {
    // 是否展示顶部及tab侧面待办数量
    showTsakNum() {
      // 未承接1 不展示
      if (this.status === 1) {
        return false;
      } else if (this.status === 2) {
        // 承接中2 竞价未推送不展示 其余状态（定价，竞价已推送）展示
        // isSend   0 未推送 1已推送
        return this.isSend === 1;
      } else {
        // 承接记录（已报停）3 中的
        // goodsBillType  1定价报车 2 竞价报车
        return this.goodsBillType === 1;
      }
    },
  },
  created() {
    this.showDetail();
  },
  mounted() {
    const box = this.$refs.scrollWrap;
    box.addEventListener(
      'scroll',
      () => {
        this.showBackTop = this.$refs.scrollWrap.scrollTop > 215;
      },
      false,
    );
  },
  methods: {
    initTab() {
      this.orderId = +this.$route.query.id;
      this.status = +this.$route.query.status;
      this.goodsBillType = +this.$route.query.goodsBillType;
      this.isSend = +this.$route.query.isSend;
      this.activeTabIndex = +this.$route.query.tabIndex || 0;
      if (this.status === 2 && this.isSend === 1) {
        this.tabs = [{ label: '承接推送信息', value: 0, taskNum: this.countInfo.pushCount },
          { label: '待提交货主', value: 1, taskNum: 0 },
          { label: '待货主确认', value: 2, taskNum: 0 },
          { label: '货主确认', value: 3, taskNum: 0 },
          { label: '改派/取消', value: 4, taskNum: 0 }];
      }
      if (this.status === 3) {
        if (this.goodsBillType === 1) {
          this.tabs = [
            { label: '已确认', value: 0, taskNum: 0 },
            { label: '已取消', value: 1, taskNum: 0 },
          ];
        } else {
          this.tabs = [
            { label: '承接推送信息', value: 0 },
          ];
        }
      }
      if (this.showTsakNum) {
        this.getCount();
      } else {
        this.tabs = this.tabMap[this.status];
      }
    },
    showDetail() {
      this.initTab();
      this.getDetail();
    },
    getCount() {
      supplyTask.orderGoodsRecordXwCount({ id: this.orderId }).then(res => {
        this.countInfo = res || {};
      }).finally(() => {
        // 仅承接中和承接记录需要展示数量
        const tabsMap = {
          2: [{ label: '承接推送信息', value: 0, taskNum: this.countInfo.pushCount },
            { label: '待提交货主', value: 1, taskNum: this.countInfo.readySubmitGoodsCount },
            { label: '待货主确认', value: 2, taskNum: this.countInfo.readyGoodsConfirmCount },
            { label: '货主确认', value: 3, taskNum: this.countInfo.goodsPassConfirmCount },
            { label: '改派/取消', value: 4, taskNum: this.countInfo.changeCount || 0 + this.countInfo.cancelCount || 0 }],
          // 3: [
          //   { label: '已确认', value: 0, taskNum: this.countInfo.goodsPassConfirmCount },
          //   { label: '已取消', value: 1, taskNum: this.countInfo.cancelCount },
          // ],
        };
        // 承接记录详情 定价单与竞价单有区别
        if (this.status === 3) {
          if (this.goodsBillType === 1) {
            this.tabs = [
              { label: '已确认', value: 0, taskNum: this.countInfo.goodsPassConfirmCount },
              { label: '已取消', value: 1, taskNum: this.countInfo.cancelCount },
            ];
          } else {
            this.tabs = [
              { label: '承接推送信息', value: 0 },
            ];
          }
        } else {
          this.tabs = tabsMap[this.status];
        }
      });
    },
    refreshCount() {
      if (this.showTsakNum) {
        this.getCount();
      }
    },
    // 货单信息+货单详情时间轴
    async getDetail() {
      const detail = await supplyTask.orderGoodsDetail(this.orderId).then((res) => {
        return res || {};
      });
      this.orderInformation = detail;
      if (this.status === 2 && this.orderInformation.state === 8) {
        // 场景：本身停留在承接中详情 此时刷新 此单已报停 直接跳转到承接记录（已报停）
        this.$router.replace({
          path: '/goodsOrder/supplyTask/detail',
          query: {
            id: this.orderId,
            status: 3,
            goodsBillType: this.orderInformation.type,
          },
        });
        this.initTab();
      }
    },

    // 左侧展开
    foldLeft() {
      this.isfoldLeft = !this.isfoldLeft;
    },
    // 切换tab
    changeTab(data) {
      this.activeTabIndex = Number(data.value);
      // 暂无多余tab接入 暂时写法 后期提炼
      this.componentKey = this.componentKey + 1;
      this.refreshCount();
    },
    // tab下重新刷新tab数据
    refreshData() {
      this.componentKey = this.componentKey + 1;
      this.refreshCount();
      // 此处特殊处理 竞价模式下type2 status2 isSend===0  且是承接推送信息模块activeTabIndex0 刷新 需要刷新下详情接口获取bidSubmit最新值 便于放开竞价模式下承接推送信息模块下方得推送小五模块
      if (+this.orderInformation.type === 2 && +this.status === 2 && +this.isSend === 0 && +this.activeTabIndex === 0) {
        this.getDetail();
      }
    },
    // 来自货源承接操作后 刷新页面 实现载入承接中详情效果
    toSendDetail() {
      this.showDetail();
    },
    backToTop() {
      const container = document.querySelector('#scroll-wrap');
      container.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/goodsOrder.scss";
</style>
